@font-face {
	font-family: Ubuntu;
	src: url('../fonts/Ubuntu/Ubuntu-Regular.ttf');
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: Ubuntu;
	src: url('../fonts/Ubuntu/Ubuntu-Bold.ttf');
	font-weight: 700;
	font-style: normal;
}

@font-face {
	font-family: Ubuntu;
	src: url('../fonts/Ubuntu/Ubuntu-Italic.ttf');
	font-weight: normal;
	font-style: italic;
}

@font-face {
	font-family: Ubuntu;
	src: url('../fonts/Ubuntu/Ubuntu-BoldItalic.ttf');
	font-weight: 700;
	font-style: italic;
}

@font-face {
	font-family: Ubuntu;
	src: url('../fonts/Ubuntu/Ubuntu-Light.ttf');
	font-weight: 300;
	font-style: normal;
}

@font-face {
	font-family: Ubuntu;
	src: url('../fonts/Ubuntu/Ubuntu-LightItalic.ttf');
	font-weight: 300;
	font-style: italic;
}

@font-face {
	font-family: Ubuntu;
	src: url('../fonts/Ubuntu/Ubuntu-Medium.ttf');
	font-weight: 500;
	font-style: normal;
}

@font-face {
	font-family: Ubuntu;
	src: url('../fonts/Ubuntu/Ubuntu-MediumItalic.ttf');
	font-weight: 500;
	font-style: italic;
}

