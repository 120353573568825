// Body
$body-bg: hsla(0, 0%, 100%, 1);

// Typography
$font-family-sans-serif: 'Ubuntu', sans-serif;
$font-size-base: 0.9rem;
$line-height-base: 1.6;

// Colors
$blue: #3490dc;
$indigo: #6574cd;
$purple: #9561e2;
$pink: #f66d9b;
$red: #e3342f;
$orange: #EB6402;
$yellow: #ffed4a;
$green: #1DAB44;
$green: #38c172;
$teal: #4dc0b5;
$cyan: #6cb2eb;

// CAN-TZ Colors
$black: #000000;
$accent: #EB6402;
$ghost: #E5E5E5;
$ghost2: #D9E0E9;
$shout: #FFB800;

// Rainbows
$rainbow1: hsla(175, 100%, 29%, 1);
$rainbow2: hsla(122, 39%, 49%, 1);
$rainbow3: hsla(88, 50%, 53%, 1);
$rainbow4: hsla(66, 70%, 54%, 1);
$rainbow5: hsla(54, 100%, 62%, 1);
$rainbow6: hsla(45, 100%, 52%, 1);
$rainbow7: hsla(339, 84%, 50%, 1);
$rainbow8: hsla(219, 41%, 58%, 1);



$primary-darker: hsla(88, 50%, 19.7%, 1);
$primary-dark: hsla(88, 50%, 36.3%, 1);
$primary: hsla(88, 50%, 53%, 1);
$primary-light: hsla(88, 50%, 69.7%, 1);
$primary-lighter: hsla(88, 50%, 86.3%, 1);

$secondary-darker: hsla(25, 98%, 12.7%, 1);
$secondary-dark: hsla(25, 98%, 29.3%, 1);
$secondary: hsla(25, 98%, 46%, 1);
$secondary-light: hsla(25, 98%, 62.7%, 1);
$secondary-lighter: hsla(25, 98%, 79.3%, 1);

$tertiary-darker: hsl(122.4, 39.2%, 15.7%);
$tertiary-dark: hsl(122.4, 39.2%, 32.4%);
$tertiary: hsla(122.4, 39.2%, 49%, 1);
$tertiary-light: hsl(122.4, 39.2%, 65.7%);
$tertiary-lighter: hsl(122.4, 39.2%, 82.4%);

$secondary-blue-1: hsla(229, 52%, 37%, 1);
$secondary-blue-2: hsla(210, 77%, 38%, 1);

$neutral-darker: hsla(214, 27%, 21.3%, 1);
$neutral-dark: hsla(214, 27%, 38%, 1);
$neutral: hsla(214, 27%, 54.7%, 1);
$neutral-light: hsla(214, 27%, 71.3%, 1);
$neutral-lighter: hsla(214, 27%, 88%, 1);
$neutral-lightest: hsla(214, 27%, 94.7%, 1);
$white: hsla(0, 0%, 100%, 1);
$dark: hsla(0, 0%, 20%, 1);

$accent2: hsla(43, 100%, 50%, 1);