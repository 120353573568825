/* back to top button */
.back-to-top {
	position: fixed;
	bottom: 0;
	right: 0;
	display: inline-block;
	margin: .5em;
	font-size: 1rem;
	padding: .5rem 1rem;
	z-index: 9999;
	border-radius: 3px;
	background-color: $accent;
	color: #fff;
}

.back-to-top:hover {
	cursor: pointer;
	background-color: darken($color: $accent, $amount: 3);
	color: #fff;
}