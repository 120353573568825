// Fonts
// @import url('https://fonts.googleapis.com/css?family=Roboto:300,400,700,500,100');
// @import url('https://fonts.googleapis.com/css?family=Nunito');

@import url('https://fonts.googleapis.com/css2?family=Lato:wght@100;300;400;700&display=swap');

@import 'fonts';
// Variables
@import 'variables';

// components
@import 'backtop';

// Bootstrap
@import '~jbox/dist/jBox.all.min.css';
@import '~bootstrap/scss/bootstrap';
@import '~animate.css/animate.min.css';
@import '~aos/dist/aos.css';
@import '~sweetalert2/src/sweetalert2';
@import '~@fortawesome/fontawesome-free/scss/fontawesome';
@import '~@fortawesome/fontawesome-free/scss/solid';
@import '~@fortawesome/fontawesome-free/scss/brands';

a,
a:hover {
	text-decoration: none;
	transition: all .15s ease-in-out;
}

.bg-green {
	background-color: $primary !important;
}

.bg-orange {
	background-color: $secondary-light !important;
}

.bg-orange2 {
	background-color: #FFB800 !important;
}

.bg-ghost {
	background-color: $neutral-lighter !important;
}

.sliced {
	position: relative;
	-webkit-clip-path: polygon(0 0, 0 100%, 100% 100%, 100% 100%, 75% 0);
	clip-path: polygon(0 0, 0 100%, 100% 100%, 100% 100%, 75% 0);
	transition: all .5s ease-in-out;
	overflow: hidden;

	span {
		margin-left: -1.5rem;
	}

	&:before {
		content: '⇒';
		position: absolute;
		opacity: 0;
		top: .3rem;
		left: -20px;
		transition: 0.5s;
	}

	&:hover {
		transition: all .2s ease-in-out;
		padding-left: 1.1rem;
		color: #222;

		&:before {
			left: .4rem;
			opacity: 100%;
			transition: 0.5s;
		}
	}
}

.sliced-inverse {
	position: relative;
	-webkit-clip-path: polygon(0 0, 100% 0%, 100% 100%, 25% 100%, 0 0);
	;
	clip-path: polygon(0 0, 100% 0%, 100% 100%, 25% 100%, 0 0);
	;
	transition: all .5s ease-in-out;
	overflow: hidden;

	span {
		display: inline-block;
		right: 10px;
		position: absolute;
	}

	&:before {
		content: '⇒';
		position: absolute;
		opacity: 0;
		top: .3rem;
		left: -20px;
		transition: 0.5s;
	}

	&:hover {
		span {
			//right: 0;
		}

		transition: all .2s ease-in-out;
		padding-right: 1.1rem;
		color: #222;
		width: 50% !important;

		&:before {
			left: 30px;
			opacity: 100%;
			transition: 0.5s;
		}
	}
}

.accent-border {
	height: 7px;
	background-color: $secondary;
	width: 100%;
	margin: 2rem 0 6rem;
}

.title-underlined {
	position: relative;
	margin-bottom: .5em;
	line-height: 1.5em;

	&:after {
		content: '';
		position: absolute;
		height: 3px;
		bottom: -3px;
		width: 20%;
		left: 0%;
		background-color: #EB6402;
		z-index: 3;
	}

	&:before {
		content: '';
		position: absolute;
		height: 3px;
		bottom: -3px;
		width: 40%;
		left: 20%;
		background-color: #FFFFFF;
		z-index: 3;
	}
}

.left-lined {
	position: relative;
	font-size: 1rem;

	a:link,
	a:visited {
		color: $black !important;
	}

	&:after {
		content: '';
		position: absolute;
		height: 30%;
		width: 5px;
		left: 0px;
		top: 0;
		background-color: $secondary;
		z-index: 3;
	}

	&:before {
		content: '';
		position: absolute;
		height: 100%;
		width: 5px;
		background-color: $neutral-lighter;
		z-index: 3;
	}
}

html {
	margin: 0 !important;
	padding: 0 !important;
}

*,
*::before,
*::after {
	box-sizing: border-box;
}

body {
	background-color: $body-bg;
	font-family: $font-family-sans-serif;
	line-height: $line-height-base
}

.boxed {
	padding: 0;
	max-width: 1280px !important;
	margin: auto;
	width: 97%;
}

section {

	>.section-title,
	div>.section-title {
		color: #000;
		font-weight: 700;
		font-size: 2.5rem;
		margin-bottom: unset !important;

		display: flex;
		justify-content: space-between;
		align-items: center;

		>.float-right {
			font-weight: 300;
			font-size: 1rem;

			>a {
				color: #000;
				display: flex;
				align-items: center;

				>img {
					margin-left: 1em;
				}
			}
		}
	}

	>.section-subtitle,
	div>.section-subtitle {
		color: $neutral-dark;
		font-weight: 300;
		font-size: 1.5rem;
	}

	>.section-body {
		padding: 3rem 0;
	}
}

.placeholder {
	height: 128px;
	display: none;
}

#nav-box {
	display: flex;
	background-color: $primary;
	z-index: 999;

	.logo {
		flex: 0 1 50%;
		min-height: 100%;
		max-width: 25%;
		padding: 0.5em;
		display: flex;
		align-items: center;
		align-self: center;
		text-align: center;

		img {
			width: 70%;
		}
	}

	.nav {
		flex: 0 1 100%;
		display: flex;
		height: 100%;
		max-width: 100%;
		flex-wrap: wrap;
		align-items: flex-start;
	}

	.main-nav {
		position: relative;
		flex: 0 0 100%;
		max-width: 100%;
		padding: .5rem;
		overflow: hidden;
		background-color: $primary-dark;

		div.green-box {
			position: absolute;
			background-color: hsla(88, 50%, 53%, 1);
			border-top-left-radius: 15px;
			border-top-right-radius: 15px;
			height: 100px;
			max-width: 180px;

			h4.green-box-title {
				font-size: 0.8rem !important;
				font-weight: 700;
				color: hsla(0, 100%, 0%, 1) !important;
				margin-bottom: .1rem !important;
			}

			p.green-box-text {
				font-size: .8rem;
				line-height: 1.2;
				color: hsla(88, 50%, 19.7%, 1) !important;
			}

			&:hover {
				background-color: hsla(88, 50%, 50%, 1) !important;
				transition: all .2s ease-in-out;
			}
		}
	}

	.sub-nav {
		flex: 0 0 100%;
		width: 100%;
		height: 50px;
		overflow: hidden;
		background-color: $neutral-lighter;

		div.alt-menu {
			width: 90%;
			height: 50px !important;
			margin: 0 auto;
			display: flex;
			justify-content: space-between;
			align-items: center;
			font-size: .8rem;

			>a {
				color: $neutral-dark;
			}

			>a.active {
				color: $secondary;
			}

			>.bull {
				font-size: 1.5rem;
				color: $neutral-light;
			}
		}

		div.social-links {
			max-width: 60%;
			margin: 0 auto;
			height: 50px !important;
			display: flex;
			align-items: center;
			justify-content: space-between;

			a.social-link {
				display: block;

				img {
					width: 20px;
				}
			}
		}
	}

	nav {
		width: 95%;
		margin: 0 auto;

		ul.navbar-nav {
			width: 100%;
			display: flex !important;
			flex-wrap: nowrap;
			justify-content: space-between !important;

			>li {
				font-size: 1rem !important;
				text-align: center;

				&.active {
					>a {
						color: #fff !important;
					}
				}

				>a {
					&:not(.offset-menu) {
						text-transform: uppercase;
						font-size: 12px;
						font-weight: 700;
						color: #000;
					}

					&:hover {
						color: $white;
						transition: all .2s;
					}
				}

				>div.offset-menu {
					margin-top: -0.3rem;

					>a {
						color: $primary-darker !important;
						font-size: 0.8rem !important;
					}
				}
			}

			.nav-link {
				padding-top: 0 !important;
				padding-bottom: 0 !important;
			}
		}
	}
}

.mobile-nav {
	.nav-item {
		.nav-link {
			font-size: 1.25rem;
			color: $primary-darker;
			border-bottom: 1px solid $primary;
			padding: 1rem 0 !important;


			h4 {
				font-size: 1.4rem;
				font-weight: 700;
			}
		}

		.mobile-social-links {
			margin-top: 2rem;
			display: flex;
			justify-content: space-between;
			align-items: center;

			a.mobile-social-link {
				display: block;
				img {
					width: 35px;
				}
			}
		}
	}
}

#carousel {
	margin-top: 3rem;
	border-radius: 30px;
	overflow: hidden;
	position: relative !important;

	.carousel-inner {
		height: 520px !important;
		position: relative;
		width: 100%;
	}

	.carousel-item {
		position: relative;

		img {
			object-fit: cover;
			width: 100%;
			height: auto;
		}
	}

	.carousel-caption {
		position: absolute;
		height: 520px;
		top: 0;
		right: 0;
		background-color: transparent;
		overflow: hidden;

		.caption {
			position: absolute;
			background-color: $secondary-blue-2;
			width: 350px;
			border-top-right-radius: 30px;
			border-bottom-right-radius: 30px;
			top: 0;
			right: 0;
			height: 100%;
			color: $black;
			padding: 3rem;
			text-align: left;

			h2 {
				font-weight: 700;
				font-size: 1.3rem;
			}

			p {
				font-weight: 300;
				font-size: 0.9rem;
			}

			.tri-left {
				width: 50px;
				height: 50px;
				background-color: $secondary-blue-2;
				position: absolute;
				left: -25px;
				transform: rotate(45deg);
			}
		}
	}

	.carousel-controls {
		width: 140px;
		height: 60px;
		position: absolute;
		bottom: 1rem;
		right: 170px;

		z-index: 999;
	}

	.carousel-accent-border {
		background-color: #fff;
		height: 7px;
		width: 100px;
		margin-bottom: 2rem;
	}

}


#page-header {
	position: relative;
	left: 0px;
	top: 0px;
	margin: 0px;
	padding: 0px;
	background-color: #c4c4c4;
	z-index: 1;
	clear: both !important;

	//max-height: 400px;
	>.header-content {
		position: relative;
		display: block;
		overflow: hidden;
		max-height: 500px;
		width: 100%;
		margin-right: -100%;
		-webkit-backface-visibility: hidden;
		backface-visibility: hidden;

		.header-caption {
			position: absolute;
			right: 2.5%;
			max-width: 1280px;
			bottom: .5rem;
			left: 2.5%;
			z-index: 10;
			padding-top: 1rem;
			color: #fff;

			>.page-header {
				line-height: 1rem;
			}

			>.page-subheader {
				font-weight: lighter;
			}

		}
	}
}

#content {
	min-height: 300px;
}

.tint {
	position: absolute;
	width: 100%;
	height: 100%;
	margin: 0;
	padding: 0;
	top: 0;
	background-color: rgba(0, 0, 0, 0.4);
	z-index: 2;
}


section {
	margin-top: 4rem;
	margin-bottom: 2rem;
}

.fancy-cards {
	margin: 0px;
	padding: 0px;
	cursor: pointer;
	color: #000 !important;

	.fancy-card {
		padding: 1.5rem 2.2rem 0;
		transition: all .2s;
		height: 450px;
		margin-bottom: 1rem;
		position: relative;
		color: currentColor;

		.fancy-card-header {
			display: flex;
			align-items: center;
			margin-bottom: 1rem;

			.icon {
				font-size: 1rem;
				margin-right: 1.2rem;
			}

			h3 {
				font-weight: 700;
				color: #000 !important;
				font-size: 1.5rem;
			}
		}

		.fancy-card-content {
			>p {
				color: #000 !important;
				font-size: .8rem;
				font-weight: 300;
			}
		}

		.fancy-card-footer {
			width: 80%;
			position: absolute;
			bottom: 2rem;
			font-weight: bold;
			display: flex;
			justify-content: space-between;
			align-items: center;
			color: #000 !important;


			.float-right i {
				font-size: 2rem;
			}
		}

		&:hover {
			margin-top: -1rem;
		}
	}

	>div:nth-child(1) .fancy-card {
		background-color: $rainbow1;
	}

	>div:nth-child(2) .fancy-card {
		background-color: $rainbow2;
	}

	>div:nth-child(3) .fancy-card {
		background-color: $rainbow3;
	}

	>div:nth-child(4) .fancy-card {
		background-color: $rainbow4;
	}

	>div:nth-child(5) .fancy-card {
		background-color: $rainbow5;
	}

	>div:nth-child(6) .fancy-card {
		background-color: $rainbow6;
	}

	>div:nth-child(7) .fancy-card {
		background-color: $rainbow7;
	}

	>div:nth-child(8) .fancy-card {
		background-color: $rainbow8;
	}
}

.vertical-selector {
	// border-radius: 20px;
	border: 5px solid $neutral-lighter;
	min-height: 100%;
	font-size: 1rem;

	ul {
		list-style-type: none;
		width: 100%;
		padding: 2rem 0px 0px 0px;
		margin: 0px;

		>li {
			width: 100%;
			padding: .7rem 2rem;
			cursor: pointer;

			&.active {
				background-color: $neutral-lighter;
			}

			&:hover {
				background-color: $neutral-lighter;
			}
		}
	}
}

.tab-content {
	display: none;

	&.active {
		display: block;
	}
}

.logos {
	.company-logo {
		cursor: pointer;
		display: block;
		min-height: 10rem;
		// border-radius: 20px;
		margin: 3px;

		.logo {
			display: flex;
			justify-content: center;
			align-items: center;
			text-align: center;
			height: 10rem;
			// border-radius: 20px;
			padding: .7rem;

			>img {
				width: 65%;
				// max-width: 60%;
				align-self: center;
			}
		}

		&-extra {
			>div {
				background-color: $accent2;
			}

			.title {
				// font-weight: 700;
				font-size: 1.2rem;
				line-height: 1.2;
				color: $secondary-darker;
			}

			.link {
				// border-radius: 99px;
				display: block;
				font-size: .9rem;
				font-weight: 700;
				color: $accent;
				width: 80%;
				margin: 0 auto;
				padding: .5rem 1rem;
				background-color: $white;
			}
		}
	}
}

.newsletter-container {
	background-color: $neutral-lightest;
	margin-bottom: 0;
	padding: 80px 0;

	.section-header {
		max-width: 70%;
	}

	.newsletter {
		display: flex;
		justify-content: flex-end;
		align-items: center;

		input {
			padding: 1.7rem 1.5rem;
			font-size: 1rem;
			font-weight: 300;
			border: none;
			border-radius: 10px;
		}

		.newsletter-form {
			width: 70%;
			font-size: 1rem;
		}

		.newsletter-sub-btn {
			display: flex;
			justify-content: space-between;
			width: 100%;
			align-items: center;
			padding: .8rem 1.5rem;
			font-weight: 700;
			font-size: 1rem;
			background-color: $tertiary;
			color: $white;
			border: none;
			border-radius: 10px;
			box-shadow: 0 2px 10px transparent;
			transition: all .3s;

			img {
				height: 20px;
				width: auto;
			}

			&:hover {
				box-shadow: 0 5px 10px rgba($tertiary, .3);
			}
		}

	}

	.newsletter .single {
		max-width: 650px;
		margin: 0 auto;
		text-align: center;
		position: relative;
		z-index: 2;
	}

	.newsletter .single h2 {
		font-size: 22px;
		color: white;
		text-transform: uppercase;
		margin-bottom: 40px;
	}

	.newsletter .single .form-control {
		height: 50px;
		background: rgba(255, 255, 255, 0.6);
		border-color: transparent;
		border-radius: 20px 0 0 20px;
	}

	.newsletter .single .form-control:focus {
		box-shadow: none;
		border-color: #243c4f;
	}

	.newsletter .single .btn {
		min-height: 50px;
		border-radius: 0 20px 20px 0;
		background: #243c4f;
		color: #fff;
	}

	footer {
		margin: 0 !important;
	}

}


.publications {
	div.publication {
		position: relative;
		background-repeat: no-repeat;
		background-size: cover;
		min-height: 200px;
		padding: 16rem 2.5rem 2.5rem 2.5rem;
		color: #fff;

		>.shade {
			background-color: #000 !important;
			opacity: 50%;
			position: absolute;
			width: 100%;
			height: 100%;
			top: 0;
			left: 0;
			z-index: 1;
		}

		.pub-body {
			position: relative;
			z-index: 2;
		}

		.pub-title {
			min-height: 5rem;
		}

		.pub-content {
			z-index: 3;
			width: 100%;
			overflow: hidden;
			text-overflow: ellipsis;
			-webkit-line-clamp: 4;
			-webkit-box-orient: vertical;
			display: -webkit-box;
			height: 95px;
		}

		.pub-date {
			position: relative;
			height: 24px;

			span.pd-date {
				left: 30px;
				top: 3px;
				position: absolute;
				height: 24px;
			}

			span.pd-icon {
				position: absolute;
				top: 0;
				width: 24px;
			}
		}

		.pub-footer {
			width: 100%;
			display: block;
			height: 40px;
		}
	}
}

.news {
	.news-item {
		border-radius: calc(1rem + 20px);
		padding: 1rem;
		transition: all .2s;

		.news-header {
			margin-bottom: 2rem;
			height: 170px;
			overflow: hidden;
			border-radius: 20px;

			img {
				transition: all .2s;
				border-radius: 20px;
				object-fit: cover;
				backface-visibility: hidden;
			}
		}

		.news-body {
			color: $dark;
			height: auto;

			h4 {
				font-size: 1rem;
				font-weight: 700;
				margin-bottom: .5rem;
				color: currentColor !important;
				margin-bottom: 1rem;

				overflow: hidden;
				text-overflow: ellipsis;
				display: -webkit-box;
				-webkit-line-clamp: 2;
				/* number of lines to show */
				line-clamp: 2;
				-webkit-box-orient: vertical;
			}

			p {
				font-size: 1rem;
				color: currentColor !important;

				overflow: hidden;
				text-overflow: ellipsis;
				display: -webkit-box;
				-webkit-line-clamp: 4;
				/* number of lines to show */
				line-clamp: 4;
				-webkit-box-orient: vertical;

				&:last-child {
					display: flex;
					align-items: center;
					font-weight: 700;
					color: $neutral-light !important;

					img {
						margin-right: .8rem;
					}
				}
			}
		}
	}

	&:hover {
		.news-item:has(:hover) {
			background-color: $neutral-lighter;
			// .news-header {
			// 	img {
			// 		transform: scale(1.01);
			// 	}
			// }
		}

		// .news-item:not(:hover) {
		// 	.news-header {
		// 		img {
		// 			transform: scale(0.95);
		// 		}
		// 	}
		// }
	}
}

.about-box {}


.tag-images {
	padding: 2rem;
	border-radius: 20px;
	background: $neutral-lighter !important;
}

.tag-image {
	padding: 1px;
	cursor: pointer;
}

.tag-image img {
	width: 100%;
	object-fit: cover;
	transform: scale(1);
	border-radius: 10px;
	transition: all 0.3s;
}

.tag-image:hover img {
	transform: scale(1.1);
}

#caption {
	padding: 5px;
	background: #4CAE50;
	color: white;
	font-size: 1rem;
}

.team-box {
	width: 100%;
	height: 85%;
	padding: 25px;
	background-color: #D9E0E9;
}

footer {
	background-color: $tertiary;
	padding: 0;
	margin: 0;
	min-height: 250px;

	>div:first-child {
		padding: 5rem 0;
	}

	h3.title {
		color: $neutral-lighter;
		font-weight: 700;
		font-size: 1.5rem;
		text-transform: uppercase;
		margin-bottom: 2rem;
	}

	.contact-container {
		display: flex;
		justify-content: flex-start;
	}

	.quick-links-container {
		display: flex;
		justify-content: center;
	}

	.related-links-container {
		display: flex;
		justify-content: flex-end;
	}

	.contacts {}

	.quick-links {

		a:link,
		a:visited {
			color: $dark !important;
			font-size: .95rem;
		}
	}

	.related-links {

		a:link,
		a:visited {
			color: $dark !important;
			font-size: .95rem;
		}
	}

	div.links {
		font-size: .95rem;

		ul {
			list-style-type: none;
			padding: 0;
			margin: 0;

			&:hover li:has(:hover) {
				transform: translateX(5px);
			}
		}

		li {
			padding: 0.15rem 0;
			transition: all .3s;

			>a {
				text-decoration: none;
				color: black;

				img {
					width: 10px !important;
				}
			}
		}
	}

	.bottom-bar {
		padding: 1rem 0;
		border-top: 3px solid rgba($tertiary-dark, .2);
		font-size: .95rem;

		ul.bottom-bar-links {
			list-style-type: none;
			padding: 0;
			margin: 0;
			display: flex;
			justify-content: flex-start;
			align-items: center;

			li {
				margin-right: 2rem;

				a {
					color: $dark;
					text-decoration: none;
				}

				>.bull {
					display: inline-block;
					font-size: 2rem;
					color: $neutral-lighter;
					;
				}
			}


		}
	}
}

/*** Pages ***/
div.show-on-hover {
	position: absolute;
	top: 0;
	bottom: 0;
	right: 0;
	left: 0;
	text-align: center;
	color: #fff;
	background-color: rgba(235, 100, 2, 0.7);
	display: none !important;
	transition: all .2s ease-in-out;
	z-index: 10;
	font-size: .9rem;
	padding: 20% 1rem;
}

.custom-card {
	background-color: $neutral-lightest !important;
	border-radius: calc(.8rem + 20px);
	overflow: hidden;
	transition: all .3s;
	backface-visibility: hidden;
	// box-shadow: 0px 0px 10px transparent;

	&:hover .card-header img {
		// transform: scale(1.01);
		// box-shadow: 0px 5px 10px hsla(0, 05, 80%, .2);
	}

	div.card-header {
		// padding: 0;
		background-color: unset;
		border-bottom: none;
		padding: .8rem;
		overflow: hidden;

		img {
			border-radius: 20px;
			// transform: scale(1.02);
			transition: all .3s;
			backface-visibility: hidden;
		}
	}

	.card-body {
		font-size: 1rem;
	}

	.card-footer {
		background-color: unset;
		border: none;
	}

}

.vertical-menu {
	background-color: hsla(214, 27%, 94.7%, 1);
	border-radius: 20px;
	overflow: hidden;
	margin: 0;
	padding: 0;
	list-style-type: none;
	overflow: hidden;

	.vertical-menu-item {
		&:hover {
			background-color: hsla(214, 27%, 88%, 1);
			color: #000;
		}

		&.active {
			background-color: hsla(214, 27%, 88%, 1);
		}

		& a {
			padding: 1rem;
			font-size: 1rem;
			font-weight: 300;
			color: #000;
			display: block;
		}
	}
}

.list-img-box {
	padding: .4rem 1rem;
	color: #555;
	overflow: hidden;
	font-family: arial;

	.big-date {
		font-size: 6rem;
		line-height: 6rem;
		color: rgba(0, 0, 0, 0.3);
		padding: 0 15px;
		text-align: center;
	}

	.big-date-right {
		font-size: 34px;
		line-height: 37px;
		text-align: center;
		padding-left: 24px;
		color: rgba(0, 0, 0, 0.3);
	}

	.big-date-bottom {
		width: 100%;
		font-size: 14px;
		font-weight: 400;
		color: rgba(0, 0, 0, 0.3);
		text-align: left;
		bottom: 10px;
		margin-top: 1rem;
	}

	.big-date-top {
		width: 100%;
		font-size: 14px;
		color: rgba(0, 0, 0, 0.3);
		text-align: center;
	}
}


.project-details {

	div.company-logo {
		cursor: pointer;

		div.detail-summary {}

		// &.active {
		// 	div.detail-summary {
		// 		display: block;
		// 	}

		// 	border: 5px solid #EB6402 !important;
		// }

		// &:hover {
		// 	div.detail-summary {
		// 		display: block;
		// 	}

		// 	div.show-on-hover {
		// 		display: block !important;
		// 		transition: all .2s ease-in-out;
		// 	}
		// }

		// a {
		// 	text-decoration: none;
		// 	color: #333 !important;

		// 	&:hover {
		// 		text-decoration: none;
		// 	}
		// }

	}

	// div.project-details-content {

	// 	.project-description {
	// 		font-weight: 300 !important;
	// 	}

	// 	.project-impact {
	// 		background-color: #D9E0E9 !important;
	// 		padding: 1.4rem;

	// 		div.impact-block {
	// 			border: 3px solid #FFB800;
	// 			background-color: #fff;
	// 			margin: 1rem;
	// 			padding: 1rem 2rem;
	// 			min-height: 9rem;
	// 		}
	// 	}

	// 	.project-photos {}

	// 	.project-publications {}
	// }

}

.datepicker {
	z-index: 1051 !important;
}

.note-editor.note-frame.fullscreen {
	background-color: #fff;
}

.career-listing-body {
	line-height: 19px;
	font-weight: 300;

}

.bridge-card-story .card-header {
	.header-date {
		width: min-content;
		//white-space: break-spaces;
		line-height: 18px;
		font-size: 18px;
		font-weight: 900;
		letter-spacing: 2px;
		padding-bottom: 5px;
		border-bottom: 4px solid #4cae50;
	}
}

.bridge-card-story .card-body {
	min-height: 150px;
}

.bridge-card-story .card-title {
	min-height: 42px;
}

.bridge-card-story .card-title a {
	color: #000 !important;
	font-weight: 900;
	display: -webkit-box;
	-webkit-line-clamp: 2;
	line-clamp: 2;
	-webkit-box-orient: vertical;
	overflow: hidden;

}

.bridge-card-story .card-content {
	font-size: 13px !important;
	display: -webkit-box;
	-webkit-line-clamp: 3;
	line-clamp: 3;
	-webkit-box-orient: vertical;
	overflow: hidden;
	height: 65px;

}

.bridge-card-story .card-footer span {
	color: #999;
}

.bridge-card-story:hover {
	.card-header {
		background: #4cae50;

		.header-date,
		.header-date-full {
			color: #fff;
		}
	}

	.card-title a {
		text-decoration: underline;
	}

	.card-footer {
		span {
			text-decoration: underline;
		}
	}
}

.bridge-research-story .card-header {
	.header-date {
		width: min-content;
		//white-space: break-spaces;
		line-height: 18px;
		font-size: 18px;
		font-weight: 900;
		letter-spacing: 2px;
		padding-bottom: 5px;
		border-bottom: 4px solid #4cae50;
	}
}

.bridge-research-story .card-body {
	min-height: 150px;
}

.bridge-research-story .card-title {
	min-height: 42px;
}

.bridge-research-story .card-title a {
	color: #000 !important;
	font-weight: 900;
	display: -webkit-box;
	-webkit-line-clamp: 2;
	line-clamp: 2;
	-webkit-box-orient: vertical;
	overflow: hidden;

}

.bridge-research-story .card-content {
	font-size: 13px !important;
	display: -webkit-box;
	-webkit-line-clamp: 3;
	line-clamp: 3;
	-webkit-box-orient: vertical;
	overflow: hidden;

}

.bridge-research-story .card-footer span {
	color: #999;
}

.bridge-research-story:hover {
	.card-header {
		background: #4cae50;

		.header-date,
		.header-date-full {
			color: #fff;
		}
	}

	.card-title a {
		text-decoration: underline;
	}

	.card-footer {
		span {
			text-decoration: underline;
		}
	}
}



.btl {
	border-top-left-radius: 30px;
}

.btr {
	border-top-right-radius: 30px;
}

.bbl {
	border-bottom-left-radius: 30px;
}

.bbr {
	border-bottom-right-radius: 30px;
}


/** GRID **/
@media (max-width: 480px) {

	// xs
	.header-caption {
		text-align: center;
		top: 1rem;

		>.page-subheader {
			display: none
		}
	}
}

@media (max-width: 767px) {
	.news {
		padding: unset !important;
	}

	.section-subtitle {
		font-size: 1rem !important;
	}

	.boxed {
		max-width: 94% !important;
	}

	footer {
		>div:first-child {
			padding: 2rem 0;
		}

		.contact-container {
			justify-content: flex-start;
			margin-bottom: 2rem;
		}

		.quick-links-container {
			justify-content: flex-start;
			margin-bottom: 2rem;
		}

		.related-links-container {
			justify-content: flex-start;
		}

		.bottom-bar {
			ul.bottom-bar-links {
				flex-direction: column;
				align-items: flex-start;

				li {
					margin-right: 0;
					margin-bottom: .5rem;

					>.bull {
						display: none;
					}
				}


			}
		}
	}

	.btl {
		border-top-left-radius: 0px;
	}

	.btr {
		border-top-right-radius: 0px;
	}

	.bbl {
		border-bottom-left-radius: 0px;
	}

	.bbr {
		border-bottom-right-radius: 0px;
	}

	.fancy-cards {
		.fancy-card {
			height: 420px;

			.fancy-card-content {
				>p {
					font-size: 1rem;
				}
			}

			.fancy-card-footer {
				width: 85%;
			}
		}
	}

	.news {
		.news-item {
			margin-bottom: 1rem;
		}
	}

	.tag-images {
		display: none;
	}

	.about-links {
		padding-left: 3rem;
	}

	.wwww-selector {
		display: none;
	}

	.newsletter-container {
		padding: 40px 0;

		.section-header {
			max-width: 100%;
		}

		.newsletter {
			margin-top: 2rem;
			justify-content: flex-start;

			.newsletter-form {
				width: 100%;
			}
		}
	}

	#carousel {
		margin-top: 1.5rem;
		overflow: hidden;
		position: relative !important;

		.carousel-inner {
			height: 400px !important;
			position: relative;
			width: 100%;
		}

		.carousel-item {
			position: relative;

			img {
				object-fit: cover;
				width: 100%;
				height: 400px;
			}
		}

		.carousel-caption {
			display: none;
		}

		.carousel-accent-border {
			background-color: #fff;
			height: 7px;
			width: 100px;
			margin-bottom: 2rem;
		}

	}

}

@media (min-width: 768px) and (max-width: 991px) {
	// sm

}

@media (min-width: 992px) and (max-width: 1199px) {
	// md

}